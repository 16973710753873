<template>
  <div class="food-list-page w-100">
    <div class="container-fluid wrap__tabs my-6">
      <div
        v-for="(tab, idx) in tabs"
        :key="idx"
        class="tab"
        :class="{ tab__active: tab.value === currentTab }"
        @click="changeTab(tab.value)"
      >
        <div class="d-flex">
          <div
            class="mr-5 wrap-icon"
            :class="{
              'wrap-icon-card': tab.value === ACCOUNT_TYPES.AWAIT_COACHING,
            }"
          >
            <v-icon :color="tab.iconColor" large>{{ tab.icon }}</v-icon>
          </div>
          <div class="wrap-title p-2">
            <h5>{{ tab.title }}</h5>
            <div>{{ tab.customerNumber }} khách hàng</div>
          </div>
        </div>
      </div>
    </div>
    <Wrapper>
      <b-row>
        <b-col cols="3">
          <basic-input
            label="Thông tin khách hàng"
            placeholder="Nhập tên hoặc số điện thoại khách hàng"
            name="searchText"
            :value.sync="filters.searchText"
            @keyup.native.enter="loadData(false)"
            class="filter"
          ></basic-input>
        </b-col>
        <b-col cols="3">
          <basic-select
            label="Gói mua"
            placeholder="--- Chọn gói mua ---"
            name="package"
            :solid="false"
            changeValueByObject
            trackBy="value"
            valueLabel="text"
            :value.sync="filters.package"
            :options="packages"
            multiple
            @update:value="loadData(false)"
            class="filter"
          />
        </b-col>
        <b-col cols="3">
          <basic-select
            label="Trạng thái"
            placeholder="--- Chọn trạng thái ---"
            name="type"
            :solid="false"
            changeValueByObject
            trackBy="value"
            valueLabel="text"
            :value.sync="filters.status"
            :options="statuses"
            @update:value="loadData(false)"
            class="filter"
          />
        </b-col>
        <b-col v-if="showNewCustomer" cols="3">
          <basic-select
            label="Nhãn"
            placeholder="--- Chọn nhãn ---"
            name="tag"
            :solid="false"
            changeValueByObject
            trackBy="value"
            valueLabel="text"
            :value.sync="filters.statusTag"
            :options="statusTags"
            @update:value="loadData(false)"
            class="filter"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="d-flex justify-content-end align-items-center">
            <b-dropdown
              id="dropdown-right dropdown-form"
              class="dropdown-form-filter mr-2"
              no-caret
              right
              ref="dropdownFormFilter"
            >
              <template #button-content>
                <span class="svg-icon">
                  <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
                </span>
                Bộ lọc
              </template>
              <h6 class="d-flex align-items-center mb-0 p-4">
                <span class="svg-icon mr-3">
                  <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
                </span>
                Bộ lọc
              </h6>
              <b-dropdown-divider> </b-dropdown-divider>
              <b-dropdown-form :class="showNewCustomer ? 'mw-600' : 'mw-400'">
                <b-container class="p-0">
                  <b-row>
                    <b-col :cols="showNewCustomer ? 6 : 12">
                      <basic-input
                        label="Thông tin khách hàng"
                        placeholder="Nhập tên hoặc số điện thoại khách hàng"
                        name="code"
                        :value.sync="filters.searchText"
                      ></basic-input>
                    </b-col>
                    <b-col :cols="showNewCustomer ? 6 : 12">
                      <basic-select
                        label="Gói mua"
                        placeholder="--- Chọn gói mua ---"
                        name="package"
                        :solid="false"
                        :allowEmpty="true"
                        changeValueByObject
                        trackBy="value"
                        valueLabel="text"
                        :value.sync="filters.package"
                        :options="packages"
                        multiple
                      />
                    </b-col>
                    <b-col :cols="showNewCustomer ? 6 : 12">
                      <basic-select
                        label="Trạng thái"
                        placeholder="--- Chọn trạng thái ---"
                        name="status"
                        changeValueByObject
                        trackBy="value"
                        valueLabel="text"
                        :value.sync="filters.status"
                        :options="statuses"
                      />
                    </b-col>
                    <template v-if="showNewCustomer">
                      <b-col cols="6">
                        <basic-select
                          label="Nhãn"
                          placeholder="--- Chọn nhãn ---"
                          name="tag"
                          :solid="false"
                          changeValueByObject
                          trackBy="value"
                          valueLabel="text"
                          :value.sync="filters.statusTag"
                          :options="statusTags"
                          class="filter"
                        />
                      </b-col>
                      <b-col cols="6">
                        <basic-date-picker
                          format="DD/MM/YYYY"
                          name="activeDate"
                          label="Ngày kích hoạt"
                          placeholder="Nhập ngày kích hoạt"
                          v-model="filters.activeDate"
                        />
                      </b-col>
                      <b-col cols="6">
                        <basic-date-picker
                          format="DD/MM/YYYY"
                          name="boughtDate"
                          label="Ngày mua"
                          placeholder="Nhập ngày mua"
                          v-model="filters.boughtDate"
                        />
                      </b-col>
                    </template>
                  </b-row>
                </b-container>
              </b-dropdown-form>
              <b-dropdown-divider> </b-dropdown-divider>
              <div
                class="d-flex align-items-center justify-content-lg-end m-0 p-4"
              >
                <b-button
                  class="btn ml-2"
                  href="#"
                  tabindex="0"
                  @click="resetRequest"
                >
                  <span class="svg-icon">
                    <inline-svg
                      src="/media/svg/icons/Neolex/Basic/refresh-cw.svg"
                    />
                  </span>
                  Reset bộ lọc
                </b-button>
                <b-button
                  class="btn ml-2"
                  href="#"
                  tabindex="0"
                  @click="searchRequest"
                >
                  <span class="svg-icon">
                    <inline-svg
                      src="/media/svg/icons/Neolex/Basic/filter.svg"
                    />
                  </span>
                  Lọc dữ liệu
                </b-button>
              </div>
            </b-dropdown>
            <b-button
              v-if="showNewCustomer && isWritePermission"
              class="btn btn-success"
              @click="importCustomer"
            >
              <span class="svg-icon">
                <inline-svg
                  src="/media/svg/icons/Neolex/File/file-download.svg"
                />
              </span>
              Thêm mới khách hàng
            </b-button>
            <b-button
              v-if="showNewCustomer && isWritePermission"
              class="btn btn-success ml-2"
              @click="addCustomerManual"
            >
              Thêm mới manual
            </b-button>
          </div>
        </b-col>
      </b-row>
      <NewCustomer
        v-if="showNewCustomer"
        :total="total"
        :customers="customers"
        @page="pageChange"
        @size="pageSizeChange"
        @reload-data="loadData(true)"
        @sort="sortRequest"
      />
      <WaitCoaching
        v-if="showWaitCoaching"
        :total="total"
        :customers="customers"
        @page="pageChange"
        @size="pageSizeChange"
        @reload-data="loadData(true)"
        @sort="sortRequest"
      />
      <ModalImport @import-success="loadData(true)" />
      <ModalAddManual @loadData="loadData" />
    </Wrapper>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
import { GET_CUSTOMERS } from '@/core/services/store/customer.module';
import {
  ACCOUNT_TYPES,
  ACTIVE_ACCOUNT_STATUS,
  ACTIVE_ACCOUNT_STATUS_TAG,
} from '../../../core/plugins/constants';
const { mapActions } = createNamespacedHelpers('customer');
export default {
  name: 'ActiveAccount',
  components: {
    NewCustomer: () => import('./components/NewCustomer.vue'),
    WaitCoaching: () => import('./components/WaitCoaching.vue'),
    ModalImport: () => import('./components/ModalImport.vue'),
    ModalAddManual: () => import('./components/ModalAddManual.vue'),
  },
  data() {
    return {
      currentTab: ACCOUNT_TYPES.NEW_CUSTOMER,
      tabs: [
        {
          icon: 'mdi-contacts',
          title: 'Khách hàng mới',
          customerNumber: 0,
          value: ACCOUNT_TYPES.NEW_CUSTOMER,
          iconColor: '#01645A',
        },
        {
          icon: 'mdi-card-bulleted',
          title: 'Chờ coaching',
          customerNumber: 0,
          value: ACCOUNT_TYPES.AWAIT_COACHING,
          iconColor: '#FDB913',
        },
      ],
      filters: {
        searchText: null,
        package: null,
        status: null,
        statusTag: null,
        activeDate: null,
        boughtDate: null,
      },
      packages: [],
      customers: [],
      total: 0,
      page: 1,
      size: 10,
      ACCOUNT_TYPES,
      sort: {
        by: null,
        order: null,
      },
      statusTags: [
        {
          text: 'Đã mua gói mới',
          value: ACTIVE_ACCOUNT_STATUS_TAG.BOUGHT_NEW_PACKAGE,
        },
        {
          text: 'Chờ hiệu lực',
          value: ACTIVE_ACCOUNT_STATUS_TAG.WAITING_EFFECT,
        },
      ],
    };
  },
  computed: {
    showNewCustomer() {
      return this.currentTab === ACCOUNT_TYPES.NEW_CUSTOMER;
    },
    showWaitCoaching() {
      return this.currentTab === ACCOUNT_TYPES.AWAIT_COACHING;
    },
    searchParams() {
      const filter = {
        page: this.page,
        size: this.size,
        accountType: this.currentTab,
        status: this.filters.status?.value,
        tagStatus: this.filters.statusTag?.value,
        accountInfo: this.filters.searchText,
        orderBy: this.sort.by ? `${this.sort.by} ${this.sort.order}` : null,
        activeDate: this.convertDateToTimestamp(this.filters.activeDate),
        boughtDate: this.convertDateToTimestamp(this.filters.boughtDate),
      };
      const params = new URLSearchParams();
      for (const property in filter) {
        if (filter[property]) {
          params.append(property, filter[property]);
        }
      }

      if (this.filters.package?.length) {
        this.filters.package.forEach((el) => {
          params.append('packageCode', el.value);
        });
      }
      return params;
    },
    statuses() {
      if (this.currentTab === ACCOUNT_TYPES.NEW_CUSTOMER) {
        return [
          {
            text: 'Chưa tạo tài khoản',
            value: ACTIVE_ACCOUNT_STATUS.NO_ACCOUNT,
          },
          {
            text: 'Chờ kích hoạt',
            value: ACTIVE_ACCOUNT_STATUS.WAITING_ACTIVE,
          },
          {
            text: 'Đã kích hoạt',
            value: ACTIVE_ACCOUNT_STATUS.ACTIVED,
          },
        ];
      }
      return [
        {
          text: 'Chờ coaching',
          value: ACTIVE_ACCOUNT_STATUS.WAITING_COACHING,
        },
        {
          text: 'Chờ phân nhóm',
          value: ACTIVE_ACCOUNT_STATUS.WAITING_ASSIGN_GROUP,
        },
      ];
    },
    isWritePermission() {
      return this.$route.meta.isWritePermission;
    },
  },
  async created() {
    await Promise.all([this.loadData(true), this.getPackages()]);
  },
  methods: {
    ...mapActions({ GET_CUSTOMERS }),
    changeTab(tab) {
      this.currentTab = tab;
      this.total = 0;
      this.page = 1;
      this.size = 10;
      this.customers = [];
      (this.filters = {
        searchText: null,
        package: null,
        status: null,
        statusTag: null,
        activeDate: null,
        boughtDate: null,
      }),
        this.loadData();
    },
    async getPackages() {
      const { data, error } = await this.$api.get('/Package', {
        params: {
          isRoadmap: true,
        },
      });
      if (error) return;
      this.packages = data.map((el) => ({
        text: el.name,
        value: el.code,
        id: el.id,
        name: el.name,
      }));
    },
    async loadData(isLoadCustomerCount = false) {
      const payload = {
        params: this.searchParams,
        isLoadCustomerCount,
      };
      const { data, error } = await this.GET_CUSTOMERS(payload);

      if (error) return;
      this.customers = data.items;
      this.total = data.total;

      if (isLoadCustomerCount) {
        this.tabs[0].customerNumber = data.newCustomerTotal;
        this.tabs[1].customerNumber = data.waitForClassificationTotal;
      }
    },
    pageChange(page) {
      this.page = page;
      this.loadData();
    },
    pageSizeChange(size) {
      this.size = Number(size);
      this.loadData();
    },
    importCustomer() {
      this.$bvModal.show('modal-import');
    },
    addCustomerManual() {
      this.$bvModal.show('modal-manual');
    },
    sortRequest(val) {
      this.sort.by = val.column;
      this.sort.order = val.order;
      this.loadData();
    },
    searchRequest() {
      this.loadData();
      document.body.click();
    },
    resetRequest() {
      this.filters = {
        searchText: null,
        package: null,
        status: null,
        activeDate: null,
        boughtDate: null,
      };
      this.loadData();
      document.body.click();
    },
  },
};
</script>
<style lang="scss">
.tab-active {
  background-color: #fff !important;
  border: 1px solid #01645a;
}
</style>

<style lang="scss" scoped>
.wrap-icon {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-content: center;
  background: #e4f5f5;
  border-radius: 8px;
}

.wrap__tabs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 25px;

  .tab {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    cursor: pointer;

    &.tab__active {
      border: 1.5px solid #fdb913;
    }
  }
}

.wrap-icon-calender {
  background: #e6f6ed;
}
.wrap-icon-card {
  background: #fff7c0;
}
.wrap-icon-group {
  background: #feeddc;
}

.wrap-title {
  h5 {
    text-align: left;
  }

  div {
    color: #000;
    text-align: left;
  }
}
.mw-400 {
  min-width: 400px;
}
.mw-600 {
  min-width: 600px;
}
</style>
<style lang="scss">
.filter,
.filter .form-group {
  margin-bottom: 0;
}
</style>
